import {isAuthenticated} from "../plugins/AuthService";
/* Actions */
export const setCustomer = (customerInfo) => ({
    type: 'SET_CUSTOMER_INFO',
    customerInfo
});

/* Reducers */

const guest = {
    "iss": null,
    "sub": null,
    "exp": null,
    "iat": null,
    "jti": null,
    "unm": "Guest",
    "eml": null,
    "type": null,
    "root": true
}

export const fetchCustomerInfo = (state = guest, action) => {
    const customer = isAuthenticated() ? isAuthenticated() : guest;
    return customer;
};
