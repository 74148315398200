import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useTranslation} from "react-i18next";
import theme from "../../../theme";
import axios from "axios";
import {login} from "../../../plugins/AuthService";
import {useHistory} from "react-router";
import 'react-notifications/lib/notifications.css';
import { NotificationManager} from 'react-notifications';
import {setMenuItems} from "../../../redux/store";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        width: 64,
        height: 64
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(8),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        '& span': {
            color: theme.palette.common.white
        }
    },
    image: {
        width: '100%'
    }
}));

export default function SignIn(props) {
    const {t} = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch()

    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    });

    useEffect(()=>{
        dispatch(setMenuItems([]))
        // eslint-disable-next-line
    },[])

    const onChange = ({target: {name, value}}) => {
        setCredentials({...credentials, [name]: value})
    };

    const onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        axios.post('/api/v1/login', credentials)
            .then(response => {
                if (response.data.id) {
                    login();
                    NotificationManager.success('Authorization successful', 'Success!', 3000);
                    setTimeout(() => {
                        response.data.is_manager ? history.push('/admin') : history.push('/dashboard')
                        history.go()
                    }, 1000)

                }
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
            })
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Typography component="h1" variant="h5" style={{color: theme.palette.primary.main}}>
                    {t('authorization')}
                </Typography>
                <form className={classes.form} noValidate onSubmit={onSubmit}>
                    <TextField
                        variant="outlined"
                        color="secondary"
                        margin="normal"
                        required
                        fullWidth
                        label={t('email')}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={credentials.email}
                        onInput={onChange}
                    />
                    <TextField
                        variant="outlined"
                        color="secondary"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t('password')}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={credentials.password}
                        onInput={onChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                    >
                        {t('signin')}
                    </Button>
                </form>
            </div>
        </Container>
    );
}
