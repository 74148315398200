import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from "react-i18next";
import axios from 'axios'
import 'react-notifications/lib/notifications.css';
import {NotificationManager} from 'react-notifications';
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import {fade} from "@material-ui/core";
import CurrencyFormat from "react-currency-format";
import Preloader from "../common/Preloader";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        width: 64,
        height: 64
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flex: 1,
        '& span': {
            // color: theme.palette.common.white
        }
    },
    image: {
        width: '100%'
    },
    formControl: {
        flex: 1,
        display: 'flex'
    },
    radioGroup: {
        display: 'flex',
        justifyContent: "space-around",
        marginTop: 16,
        marginBottom: 8
    },
    formControlLabel: {
        borderRadius: 9,
        border: '1px solid #DDD',
        padding: 8,
        minWidth: 100,
        margin: 4
    },
    mainBtn: {
        // minHeight: 144,
        flexWrap: 'wrap',
        display: "block"
    },
    mainBtnIcon: {
        display: 'block',
        width: '100%',
        fontSize: '6rem',
        color: fade(theme.palette.primary.main, 0.8),
        '&:hover': {
            color: fade(theme.palette.primary.main, 1)
        }
    },
    loadingOverlay: {
        position: "absolute",
        width: '100%',
        height: '95%',
        backgroundColor: 'rgba(255,255,255,0.75)',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center'
    }
}));

const pointTypes = [
    {
        type: "pulse"
    },
    {
        type: "timer"
    },
    {
        type: "onetime"
    },
    {
        type: "counter"
    },
    {
        type: "serial"
    }
]

/* DO NOT REMOVE NEXT LINES! It's NOT a comment. It's needed to auto generate missing translation fields*/
// t('point.types.pulse')
// t('point.types.timer')
// t('point.types.onetime')
// t('point.types.counter')
// t('point.types.serial')

const EditPointDialog =
    ({
         bet,
         delay,
         id,
         last_update,
         type,
         nodeid,
         onPointsUpdate
     }) => {

        const {t} = useTranslation()
        const classes = useStyles();

        const [open, setOpen] = React.useState(false);
        const [loading, setLoading] = useState(false)

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        const [newData, setNewData] = useState({
            bet,
            delay,
            id,
            last_update,
            type,
            nodeid
        });

        const onChange = ({target: {name, value}}) => {
            setNewData({...newData, [name]: value})
        };

        const onSubmit = (event) => {
            if (event) {
                event.preventDefault();
            }

            setLoading(true)
            let errors = 0;

            Object.keys(newData).forEach(e => {
                if (!newData[e].toString().length) {
                    NotificationManager.error(`${t(e)}: ${t('field_is_required')}`, 'Oops!', 6666)
                    errors++
                }
            });

            if (errors) return


            axios
                .put(
                    `/api/v1/nodes/${newData.nodeid}/points/${newData.id}`,
                    {
                        ...newData,
                        bet: parseFloat(newData.bet) * 100,
                        delay: parseFloat(newData.delay)
                    }
                )
                .then(response => {
                    if (response.data.id) {
                        NotificationManager.success(newData.id + ': ' + t('point.edit_success'), 'Success!', 5000);
                        setOpen(false);
                        setTimeout(() => onPointsUpdate(true), 333)
                    }
                })
                .catch(error => {
                    console.log(error.response)
                    NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
                })
                .finally(() => setLoading(false))
        };

        useEffect(() => {
            setNewData({...newData, bet: bet / 100})
            // eslint-disable-next-line
        }, [nodeid])

        return (
            <div style={{flexGrow: 1, padding: '0 4px'}}>
                <Button
                    variant="outlined"
                    color="primary"
                    aria-label="add"
                    onClick={handleClickOpen}
                    size="large"
                    style={{width: '100%'}}
                    className={classes.mainBtn}
                >
                    {t('point.changeBtnText')}
                </Button>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" classes={{paper: classes.paper}}>
                    <div className={classes.paper}>
                        {loading && <div className={classes.loadingOverlay}><Preloader/></div>}
                        <form className={classes.form} noValidate onSubmit={onSubmit}>
                            <DialogTitle id="form-dialog-title">{t('point.changeBtnText')} "{id}"</DialogTitle>
                            <DialogContent>
                                {/*<DialogContentText variant="caption" component="div">
                                    <div dangerouslySetInnerHTML={{__html: t('point.dialog_description')}}/>
                                </DialogContentText>*/}

                                <FormControl component="fieldset" className={classes.formControl}>
                                    <Typography variant="h5" component="legend">{t('point.types.text')}</Typography>
                                    <Divider/>
                                    <RadioGroup row aria-label="position"
                                                className={classes.radioGroup}
                                                name="type"
                                                defaultValue={newData.type}
                                                value={newData.type}
                                                onChange={onChange}
                                    >
                                        {
                                            pointTypes.map((pointType) => {
                                                return <FormControlLabel
                                                    value={pointType.type}
                                                    checked={pointType.type === newData.type}
                                                    control={<Radio color="primary"/>}
                                                    label={
                                                        <>
                                                            <small style={{top: -12, position: 'relative'}}>
                                                                {t(`point.types.${pointType.type}`)}
                                                            </small>
                                                        </>
                                                    }
                                                    labelPlacement="top"
                                                    key={pointType.type}
                                                    className={classes.formControlLabel}
                                                />
                                            })
                                        }
                                    </RadioGroup>
                                    <Divider/>
                                </FormControl>


                                <CurrencyFormat
                                    margin="dense"
                                    name="bet"
                                    label={t('point.bet')}
                                    fullWidth
                                    error={!newData.bet.toString().length}
                                    helperText={!newData.bet.toString().length ? t('field_is_required') : ' '}

                                    value={newData.bet}
                                    customInput={(props) => <TextField {...props} />}
                                    suffix=" грн."
                                    decimalScale={2}
                                    onValueChange={(values) => {
                                        const {value} = values;
                                        setNewData({...newData, bet: value})
                                    }}
                                />

                                <TextField
                                    margin="dense"
                                    name="delay"
                                    label={t('point.delay')}
                                    type="text"
                                    fullWidth
                                    value={newData.delay}
                                    onInput={onChange}
                                    error={!newData.delay.toString().length}
                                    helperText={!newData.delay.toString().length ? t('field_is_required') : ' '}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary" variant="outlined"
                                        className={classes.submit}>
                                    {t('cancel')}
                                </Button>
                                <Button type="submit" variant="contained" color="primary"
                                        className={classes.submit}>
                                    {t('point.changeBtnText')}
                                </Button>
                            </DialogActions>
                        </form>
                        {/*<pre>{JSON.stringify(newData, null, 4)}</pre>*/}
                    </div>
                </Dialog>
            </div>
        );
    }

export default EditPointDialog;
