import React, {useCallback, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';

import DashBoardNavbar from "./navigation/NavBar";
import DashDrawer from "./drawer/DashDrawer";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => (
    {
        dashMain: {
            flexGrow: 1,
            height: 'calc(100vh - 0px)',
            overflow: 'hidden'
        },
        mainContainer:{
            overflow: 'auto',
            height: 'calc(100vh - 0px)',
            overflowX: 'hidden',
            padding: theme.spacing(9,1,2,1)

        }
    }
));

const DashboardLayout = (props) => {
    const classes = useStyles();
    const {i18n} = useTranslation();

    const changeLanguage = useCallback((lng) => {
        localStorage.setItem('lang', lng);
        i18n.changeLanguage(lng);
    }, [i18n])

    useEffect(() => {
        if (localStorage.getItem('lang') && localStorage.getItem('lang') !== i18n.language) {
            changeLanguage(localStorage.getItem('lang'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <div style={{display: 'flex', background: '#FFF'}}>
            <DashBoardNavbar/>
            <DashDrawer/>

            <main className={classes.dashMain}>
                <Container maxWidth={false} disableGutters={true} className={classes.mainContainer}>
                    {props.children}
                </Container>
            </main>
        </div>
    )
};

export default DashboardLayout;
