import React from 'react'
import {Redirect, Route} from 'react-router-dom'

import MainLayout from "./index";
import * as AuthService from "../../plugins/AuthService";
import SignIn from "../../components/common/Login/SingIn";

const MainLayoutRoutes = ({ component: Component, ...rest }) => {
    return (
       /* <Route {...rest} render={props => {
            return (
                <MainLayout>
                    <Component {...props}/>
                </MainLayout>
            )
        }
        } />*/


        <Route {...rest} render={props =>

            AuthService.isAuthenticated()
                ?
                (
                    <Redirect
                        to={{
                            pathname: "/dashboard",
                            state: {from: props.location}
                        }}
                    />
                )
                : (
                    <MainLayout>
                        <SignIn/>
                    </MainLayout>
                )


        }/>

    )
}

export default MainLayoutRoutes
