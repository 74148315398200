/* Actions */
export const setExpandedItems = (expanded) => ({
    type: 'SET_EXPANDED',
    expanded
});

export const setCheckedItems = (checked) => ({
    type: 'SET_CHECKED',
    checked
});

export const setMenuOpen = (opened) => ({
    type: 'SET_OPENED',
    opened
});

export const setPreviewOpen = (opened) => ({
    type: 'SET_PREVIEW_OPENED',
    opened
});

export const setMenuItems = (sidemenuitems) => ({
    type: 'SET_SIDEMENU_ITEMS',
    sidemenuitems

});

export const setACLs = (acls) => ({
    type: 'SET_ACLS',
    acls

});


/* Reducers */
export const expandedItems = (state = [], action) => {
    switch (action.type) {
        case 'SET_EXPANDED':
            localStorage.setItem('expanded', JSON.stringify(action.payload));
            return action.payload;
        default:
            return state
    }
};

export const checkedItems = (state = [], action) => {
    switch (action.type) {
        case 'SET_CHECKED':
            localStorage.setItem('cart', JSON.stringify(action.payload));
            return action.payload;
        default:
            return state
    }
};

export const menuOpen = (state = true, action)=>{
    switch (action.type) {
        case 'SET_OPENED':
            return action.opened;
        default:
            return state
    }
};

export const previewOpen = (state = {open: false, data: {}}, action) => {
    switch (action.type) {
        case 'SET_PREVIEW_OPENED':
            return action.opened;
        default:
            return state
    }
};

export const fetchSideMenuItems = (state = [], action) => {

    switch (action.type) {
        case 'SET_SIDEMENU_ITEMS':
            return action.sidemenuitems
        default:
            return state
    }
};

export const fetchACLs = (state = [], action) => {

    switch (action.type) {
        case 'SET_ACLS':
            return action.acls
        default:
            return state
    }
};




