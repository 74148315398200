import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from "react-i18next";
import axios from 'axios'
import 'react-notifications/lib/notifications.css';
import {NotificationManager} from 'react-notifications';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import {fade} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import {DataGrid} from '@material-ui/data-grid';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // width: 500,
    },
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        width: 64,
        height: 64
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flex: 1,
        '& span': {
            // color: theme.palette.common.white
        }
    },
    image: {
        width: '100%'
    },
    formControl: {
        flex: 1,
        display: 'flex'
    },
    radioGroup: {
        display: 'flex',
        justifyContent: "space-around",
        marginTop: 16,
        marginBottom: 8
    },
    formControlLabel: {
        borderRadius: 9,
        border: '1px solid #DDD',
        padding: 8,
        minWidth: 110,
        margin: 4
    },
    mainBtn: {
        minHeight: 144,
        flexWrap: 'wrap',
        display: "block"
    },
    mainBtnIcon: {
        display: 'block',
        width: '100%',
        fontSize: '6rem',
        color: fade(theme.palette.primary.main, 0.8),
        '&:hover': {
            color: fade(theme.palette.primary.main, 1)
        }
    }
}));

export default function GetRoomTransactionsDialog(props) {

    const {t} = useTranslation()
    const classes = useStyles();

    const [transactions, setTransactions] = useState([]);

    const currencyFormatter = new Intl.NumberFormat('uk-UA', {
        style: 'currency',
        currency: 'UAH',
    });
    const datetimeFormatter = new Intl.DateTimeFormat('uk-UA', {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false
    });

    const columns = [
        {field: 'id', headerName: t('id'), width: 75, headerAlign: 'center', align: 'center'},
        {field: 'account_id', headerName: t('account_id'), width: 75, headerAlign: 'center', align: 'center'},
        {
            field: 'at',
            headerName: t('at'),
            width: 200,
            type: 'dateTime',
            valueFormatter: ({value}) => datetimeFormatter.format(new Date(value)),
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'amount',
            headerName: t('amount'),
            flex: 1,
            valueFormatter: ({value}) => currencyFormatter.format(Number(value / 100)),
            headerAlign: 'center',
            align: 'right'
        },
        {field: 'who_id', headerName: t('who_id'), width: 75, headerAlign: 'center', align: 'center'},
        {field: 'who_name', headerName: t('who_name'), flex: 1, headerAlign: 'center'},
        {field: 'note', headerName: t('note'), flex: 1, headerAlign: 'center'},
    ];

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
        axios
            .get(`/api/v1/nodes/${props.parentNode.id}/transactions`)
            .then(response => {
                console.log(response)
                setTransactions(response.data.transactions)
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
            })
    };
    const handleClose = () => setOpen(false);

    return (
        <div style={{flexGrow: 1, padding: '0 4px'}}>
            <Button
                variant="outlined"
                color="primary"
                aria-label="add"
                onClick={handleClickOpen}
                size="large"
                style={{width: '100%'}}
                className={classes.mainBtn}
            >
                <Typography variant="h6" component="h6">{t('room_transactions')}</Typography>
                <Icon fontSize="large" classes={{root: classes.mainBtnIcon}}>receipt_long</Icon>
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={"lg"}>
                <div className={classes.paper}>

                    <DialogTitle id="form-dialog-title" style={{width:'100%'}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <span/>
                            <span>{t('room_transactions')} "{props.parentNode.name}"</span>
                            <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </DialogTitle>

                    <DialogContent style={{width: '100%'}}>
                        <div style={{height: 400, width: '100%'}}>
                            {transactions.length && <DataGrid rows={transactions} columns={columns} pageSize={5}/>}
                        </div>
                    </DialogContent>
                    {/*<pre>{JSON.stringify(transactions, null, 4)}</pre>*/}
                </div>
            </Dialog>
        </div>
    );
}
