import React, {forwardRef, useEffect} from 'react';
import axios from 'axios';
import {useDispatch, useSelector} from "react-redux";
import ListItem from "@material-ui/core/ListItem";
import {makeStyles, createStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import ListItemText from '@material-ui/core/ListItemText';
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {Button, fade} from "@material-ui/core";
import {setMenuItems} from "../../redux/store";
import {NotificationManager} from "react-notifications";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import WidgetsIcon from "@material-ui/icons/Widgets";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {Link} from "react-router-dom";
import theme from "../../theme";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import {useTranslation} from "react-i18next";
import Badge from "@material-ui/core/Badge";
import Preloader from "../common/Preloader";

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 560,
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(0),
            whiteSpace: 'pre-wrap',
            "& span[class*='ListItemText-primary']": {
                paddingRight: 16,
                textAlign: 'left'
            },
            "& ul": {
                marginLeft: 12
            },
            "& div[class*='TreeItem-iconContainer']": {},

            "& [class*='topLevel'] + ul": {
                marginLeft: 52
            },
        },
        treeItemIconContainer: {
            position: 'absolute',
            right: 6,
            '& svg': {
                background: fade(theme.palette.primary.main, 0.03),
                borderRadius: '50%',
                padding: 6,
                width: 30,
                height: 30,
            }
        },

        lasted: {
            "& ul": {
                marginLeft: 12
            },

        },

        topLevel: {
            backgroundColor: fade(theme.palette.primary.main, 0.0005),
            "&:hover": {},
            "& div[class*='TreeItem-iconContainer']": {
                position: 'absolute',
                right: 0
            }
        },

        infoIconButton: {
            marginRight: 6,
            padding: 4
        },


        infoIconButtonLeft: {
            cursor: "help",
            paddingLeft: 0,
            marginRight: 6
        },
        expandIconHidden: {
            display: 'none'
        },
        listItemIcon: {
            minWidth: 24
        },
        listItemText: {
            margin: theme.spacing(0, 0, 0, 1)
        },
        permBadge: {
            opacity: 0.5,
            fontSize: '10px',
            fontWeight: 100,
            height: 12,
            padding: '2px 6px',
            // lineHeight: 10,
            top: -3,
            color: theme.palette.primary.contrastText
        }

    }),
);


const nodeIcon = type => {
    switch (type) {
        case 'node':
            return <DeviceHubIcon color="disabled" fontSize="small"/>
        case 'room':
            return <MeetingRoomIcon color="disabled" fontSize="small"/>
        case 'group':
            return <WidgetsIcon color="disabled" fontSize="small"/>
        case 'point':
            return <MoveToInboxIcon color="disabled" fontSize="small"/>
        default:
            return null

    }
}

export const NodesTree = () => {

        const classes = useStyles();
        const {t} = useTranslation()

        const sideMenuExpanded = useSelector(state => state.sideMenuExpanded);
        const sideMenuItems = useSelector(state => state.sideMenuItems);
        const open = useSelector(state => state.sideMenuOpened)

        const dispatch = useDispatch();

        // If nothing in menu is opened let's open 1st & 2nd
        if (sideMenuExpanded.length === 0) dispatch({type: 'SET_EXPANDED', payload: ['0']});

        const CustomRouterLink = forwardRef((props, ref) => {
                // console.log(props)
                return (
                    <div ref={ref} style={{flexGrow: 1}} name={props.id}>
                        <Link {...props} style={{width: 'calc(100% - 36px)', padding: 4, textTransform: 'inherit'}}/>
                    </div>
                )
            }
        );

        const handleChange = (event, nodes) => {
            const currentId = event.target.getAttribute('name')
            let expands = [];

            const getIDs = items => {
                items.forEach(item => {
                    expands.push(item.id)
                    if (item.children) getIDs(item.children)
                })
            }

            getIDs(sideMenuItems)

            //dispatch({type: 'SET_EXPANDED', payload: nodes});
            dispatch({type: 'SET_EXPANDED', payload: expands.filter(e => currentId.includes(e))});
        };

        const fetchSideMenuItems = () => {
            axios.get('/api/v1/tree')
                .then(acl_response => {
                    dispatch(setMenuItems(acl_response.data))
                })
                .catch(error => {
                    console.log(error, error.response)
                    NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
                })
        };

        useEffect(() => {
            sideMenuItems.length || fetchSideMenuItems()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [sideMenuItems, 999]);


        const getTreeItemsFromData = treeItems => {
            return treeItems.map(treeItemData => {
                let children = undefined;
                if (treeItemData.children && treeItemData.children.length > 0) {
                    children = getTreeItemsFromData(treeItemData.children);
                }
                return (
                    <TreeItem
                        className={clsx(classes.nested)}
                        key={treeItemData.id}
                        nodeId={treeItemData.id}
                        classes={{
                            content: treeItemData.parentId === '0' ? classes.topLevel : '',
                            iconContainer: classes.treeItemIconContainer
                        }}
                        label={
                            (
                                <ListItem
                                    button
                                    style={{padding: 0}}>
                                    <Button component={CustomRouterLink} to={`/node/${treeItemData.id}`} id={treeItemData.id}>
                                        <ListItemIcon className={classes.listItemIcon}>
                                            {nodeIcon(treeItemData.type)}
                                        </ListItemIcon>
                                        <ListItemText
                                            secondary={treeItemData.id}
                                            primary={
                                                <>
                                                    {treeItemData.permissions.length
                                                        ?
                                                        <Badge
                                                            badgeContent={t(`user_types.${treeItemData.permissions[0]}`)}
                                                            color="primary"
                                                            classes={{
                                                                badge: classes.permBadge
                                                            }}
                                                        >
                                                            {treeItemData.name}
                                                        </Badge>
                                                        : treeItemData.name
                                                    }
                                                </>

                                            }
                                            primaryTypographyProps={{style: {color: theme.palette.primary.main}}}
                                            className={classes.listItemText}
                                            secondaryTypographyProps={{style: {color: theme.palette.primary.light, fontStyle: "italic", fontSize: 10}}}
                                        />
                                    </Button>
                                </ListItem>
                            )
                        }
                        children={children}
                    />
                );
            });
        };

        return (
            <React.Fragment>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" color="inherit" id="nested-list-subheader">
                            {t('nodes_list')}
                        </ListSubheader>
                    }
                    className={classes.root000}
                >
                    {
                        sideMenuItems.length
                            ? <TreeView
                                expanded={sideMenuExpanded}
                                onNodeToggle={handleChange}
                                className={classes.root}
                                defaultCollapseIcon={open ? <ExpandMoreIcon/> : null}
                                defaultExpandIcon={open ? <ChevronRightIcon/> : null}
                            >
                                {getTreeItemsFromData(sideMenuItems)}
                            </TreeView>
                            : <Preloader type="linear"/>
                    }
                </List>
            </React.Fragment>
        );
    }
;
