import React, { useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from "react-i18next";
import axios from 'axios'
import 'react-notifications/lib/notifications.css';
import {NotificationManager} from 'react-notifications';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import {Divider, fade} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import WidgetsIcon from "@material-ui/icons/Widgets";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // width: 500,
    },
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        width: 64,
        height: 64
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flex: 1,
        '& span': {
            // color: theme.palette.common.white
        }
    },
    image: {
        width: '100%'
    },
    formControl: {
        flex: 1,
        display: 'flex'
    },
    radioGroup: {
        display: 'flex',
        justifyContent: "space-around",
        marginTop: 16,
        marginBottom: 8
    },
    formControlLabel: {
        borderRadius: 9,
        border: '1px solid #DDD',
        padding: 8,
        minWidth: 110,
        margin: 4
    },
    mainBtn: {
        minHeight: 144,
        flexWrap: 'wrap',
        display: "block"
    },
    mainBtnIcon: {
        display: 'block',
        width: '100%',
        fontSize: '6rem',
        color: fade(theme.palette.primary.main, 0.8),
        '&:hover': {
            color: fade(theme.palette.primary.main, 1)
        }
    }
}));

export default function GetRoomPinDialog(props) {

    const {t} = useTranslation()
    const classes = useStyles();

    const [pinInfo, setPinInfo] = useState(null);

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
        axios
            .get(`/api/v1/nodes/${props.parentNode.id}/pin`)
            .then(response => {
                console.log(response)
                setPinInfo(response.data)
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
            })
    };
    const handleClose = () => setOpen(false);

    return (
        <div style={{flexGrow: 1, padding: '0 4px'}}>
            <Button
                variant="outlined"
                color="primary"
                aria-label="add"
                onClick={handleClickOpen}
                size="large"
                style={{width: '100%'}}
                className={classes.mainBtn}
            >
                <Typography variant="h6" component="h6">{t('room_pin')}</Typography>
                <Icon fontSize="large" classes={{root: classes.mainBtnIcon}}>fiber_pin</Icon>
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <div className={classes.paper}>

                    <DialogTitle id="form-dialog-title">{t('room_pin')} {props.parentNode.name}</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="caption" component="div">
                            <div dangerouslySetInnerHTML={{__html: t('room_pin_dialog_description')}}/>
                        </DialogContentText>
                    </DialogContent>

                    <DialogContent style={{width: '100%'}}>

                        {
                            pinInfo &&
                            <>
                                <Divider/>
                                <Typography variant="h3" component="h3" style={{textAlign: "center"}}>{pinInfo.pin}</Typography>
                                <Divider/>
                                {
                                    Object.keys(pinInfo.groups).length
                                        ? <>
                                            <Typography variant="h6" component="h6" style={{textAlign: "center", marginTop: 12}}>{t('groups')}</Typography>
                                            <List component="nav" className={classes.root} aria-label="contacts">
                                                {
                                                    Object.keys(pinInfo.groups).map(group => {
                                                        return (

                                                            <ListItem button key={pinInfo.groups[group].id}>
                                                                <ListItemIcon>
                                                                    <WidgetsIcon/>
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={`ID: ${pinInfo.groups[group].id}`}
                                                                    secondary={`${t('group_name')}: "${pinInfo.groups[group].name}"`}
                                                                />
                                                            </ListItem>

                                                        )
                                                    })
                                                }

                                            </List>
                                        </>

                                        : <Typography variant="h6" component="h6" style={{textAlign: "center", marginTop: 12}}>{t('groups_not_found')}</Typography>
                                }
                                {/*<pre>{JSON.stringify(pinInfo, null, 2)}</pre>*/}
                            </>
                        }
                    </DialogContent>
                    {/*<pre>{JSON.stringify(newData, null, 4)}</pre>*/}
                </div>
            </Dialog>
        </div>
    );
}
