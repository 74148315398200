import React from 'react'
import { Route } from 'react-router-dom'

import EmptyLayout from "./index";

const EmptyLayoutRoutes = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            <EmptyLayout>
                <Component {...props} />
            </EmptyLayout>
        )} />
    )
}

export default EmptyLayoutRoutes
