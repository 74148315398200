import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from "react-i18next";
import format from 'date-fns/format'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";
import EditPointDialog from "./editPointDialog";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        padding: '0 4px',
        margin: '0 2px'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    lastUpdate: {
        fontSize: '0.75rem'
    }
});

const PointCard =
    ({
         bet,
         delay,
         id,
         last_update,
         type,
         nodeid,
         onPointsUpdate
     }) => {
        const {t} = useTranslation();
        const classes = useStyles();

        const handlePointsUpdate = update =>{
            update && onPointsUpdate(true)
        }
        return (
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Typography className={classes.pos} color="textSecondary" gutterBottom>
                        <b>{id}</b>
                        <span className={classes.lastUpdate}>
                            {t('point.last_update')}:
                            <br/>
                            <em>{format(new Date(last_update), "dd/MM/yyyy HH:mm:ss")}</em>
                        </span>
                    </Typography>
                    <Typography variant="body2" component="div">

                        <List dense>
                            <ListItem dense>
                                <ListItemIcon>
                                    <Icon>category</Icon>
                                </ListItemIcon>
                                <ListItemText
                                    primary={t('point.type')}
                                    secondary={t(`point.types.${type}`)}
                                    classes={{primary: classes.lastUpdate}}
                                />
                            </ListItem>
                            <ListItem dense>
                                <ListItemIcon>
                                    <Icon>money</Icon>
                                </ListItemIcon>
                                <ListItemText
                                    primary={t('point.bet')}
                                    secondary={`${(bet / 100).toFixed(2)} ${t('currency.default')}`}
                                    classes={{primary: classes.lastUpdate}}
                                />
                            </ListItem>
                            <ListItem dense>
                                <ListItemIcon>
                                    <Icon>traffic</Icon>
                                </ListItemIcon>
                                <ListItemText
                                    primary={t('point.delay')}
                                    secondary={delay}
                                    classes={{primary: classes.lastUpdate}}
                                />
                            </ListItem>
                        </List>
                    </Typography>
                </CardContent>
                <CardActions>
                    <EditPointDialog
                        {
                            ...{
                                bet,
                                delay,
                                id,
                                last_update,
                                type,
                                nodeid
                            }
                        }
                        onPointsUpdate={args => handlePointsUpdate(args)}
                    />
                </CardActions>
            </Card>
        );
    }

export default PointCard;

// t('point.types.pulse')
// t('point.types.timer')
// t('currency.uah')
// t('currency.usd')
