import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from "react-i18next";
import axios from 'axios'
import 'react-notifications/lib/notifications.css';
import {NotificationManager} from 'react-notifications';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import {fade} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import {DataGrid} from '@material-ui/data-grid';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Popover from "@material-ui/core/Popover";
import CreateIcon from '@material-ui/icons/Create';
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // width: 500,
    },
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        width: 64,
        height: 64
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flex: 1,
        '& span': {
            // color: theme.palette.common.white
        }
    },
    image: {
        width: '100%'
    },
    formControl: {
        flex: 1,
        display: 'flex'
    },
    radioGroup: {
        display: 'flex',
        justifyContent: "space-around",
        marginTop: 16,
        marginBottom: 8
    },
    formControlLabel: {
        borderRadius: 9,
        border: '1px solid #DDD',
        padding: 8,
        minWidth: 110,
        margin: 4
    },
    mainBtn: {
        minHeight: 144,
        flexWrap: 'wrap',
        display: "block"
    },
    mainBtnIcon: {
        display: 'block',
        width: '100%',
        fontSize: '6rem',
        color: fade(theme.palette.primary.main, 0.8),
        '&:hover': {
            color: fade(theme.palette.primary.main, 1)
        }
    },
    notePopover: {
        width: 666,
        maxWidth: 'calc(100vw - 32px)',
        padding: 32
    }
}));

const NotePopover = ({id, note, uid, nodeid, getData}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);
    const pid = open ? `note-popover-${id}-${uid}` : undefined;

    const [newNote, setNewNote] = React.useState(note);
    const handleChange = (event) => setNewNote(event.target.value);

    const onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        console.log('submit')

        axios
            .post(`/api/v1/nodes/${nodeid}/card/${uid}/note`,
                {
                    note: newNote
                }
            )
            .then(response => {
                console.log('/api/v1/nodes  response: ', response)
                handleClose()
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
            })
            .finally(() => getData())
    };


    return (
        <div>
            <Typography variant={"body2"} component={"em"}>{note}</Typography>
            <IconButton aria-describedby={pid} variant="contained" color="primary" onClick={handleClick}>
                <CreateIcon fontSize={"small"}/>
            </IconButton>
            <Popover
                id={pid}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                classes={{paper: classes.notePopover}}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <TextField
                    id="outlined-name"
                    label={t('note')}
                    value={newNote}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                />
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant={"outlined"}>
                        {t('cancel')}
                    </Button>
                    <Button onClick={onSubmit} color="primary" variant={"contained"} disabled={note === newNote}>
                        {t('save')}
                    </Button>
                </DialogActions>
            </Popover>
        </div>
    );
}

export default function GetRoomCardsListDialog(props) {

    const {t} = useTranslation()
    const classes = useStyles();

    const [cardsList, setCardsList] = useState([]);

    const columns = [
        {field: 'uid', headerName: t('cardUID'), width: 160},
        {
            field: 'note',
            headerName: t('note'),
            flex: 1,
            renderCell: (params) =>
                <NotePopover
                    {...params.row}
                    nodeid={props.parentNode.id}
                    getData={() => getData()}
                />
        },
    ];

    const [open, setOpen] = React.useState(false);

    const getData = () => {
        axios
            .get(`/api/v1/nodes/${props.parentNode.id}/cards`)
            .then(response => {
                console.log(response)
                setCardsList(response.data.cards)
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
            })

    }

    const handleClickOpen = () => {
        setOpen(true);
        getData();
    };
    const handleClose = () => setOpen(false);

    return (
        <div style={{flexGrow: 1, padding: '0 4px'}}>
            <Button
                variant="outlined"
                color="primary"
                aria-label="add"
                onClick={handleClickOpen}
                size="large"
                style={{width: '100%'}}
                className={classes.mainBtn}
            >
                <Typography variant="h6" component="h6">{t('room_customers_cards')}</Typography>
                <Icon fontSize="large" classes={{root: classes.mainBtnIcon}}>dynamic_feed</Icon>
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={"md"}>
                <div className={classes.paper}>

                    <DialogTitle id="form-dialog-title" style={{width: '100%'}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <span/>
                            <span>{t('room_customers_cards')} "{props.parentNode.name}"</span>
                            <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </DialogTitle>

                    <DialogContent style={{width: '100%'}}>
                        <div style={{height: 400, width: '100%'}}>
                            {cardsList.length && <DataGrid rows={cardsList} columns={columns} pageSize={5}/>}
                        </div>
                    </DialogContent>
                    {/*<pre>{JSON.stringify(newData, null, 4)}</pre>*/}
                </div>
            </Dialog>
        </div>
    );
}
