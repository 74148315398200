import Cookies from 'js-cookie'
import {useDispatch, useSelector} from "react-redux";
import {createHashHistory} from 'history';

export const login = () => {
    const cookieSid = Cookies.get('sid') || Cookies.get('ssid');
    window.localStorage.setItem("auth", cookieSid);
};

export const isAuthenticated = () => {
    const cookieSid = Cookies.get('sid') || Cookies.get('ssid');

    if (!cookieSid) {
        return false;
    } else {
        const user = JSON.parse(atob(cookieSid.split('.')[1]));
        return user;
    }
};

export const logout = (forceReload = false) => {
    const cookieSid = Cookies.get('sid') || Cookies.get('ssid');
    const history = createHashHistory();
    if (cookieSid) {
        Cookies.remove('sid');
        Cookies.remove('ssid');
        setTimeout(() => forceReload ? window.location.reload() : history.push('/'), 500)
    }
};

// eslint-disable-next-line
function useCustomerData() {
    // eslint-disable-next-line
    const dispatch = useDispatch()
    const customerInfo = useSelector(state => state.customerInfo);
    return () => {
        console.log(customerInfo)
    }
};
