import React, {forwardRef} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {NodesTree} from "../../../components/NodesTree";
import {setMenuOpen} from "../../../redux/store";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolBarUser: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'left',
        paddingLeft: 16,
        alignItems: 'center',
        color: theme.palette.primary.main
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 8,
        marginLeft: 36,
        [theme.breakpoints.down("sm")]: {
            marginRight: -5
        }
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginTop: 65
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function DashDrawer() {
    const {t} = useTranslation();
    const classes = useStyles();

    // const [open, setOpen] = React.useState(true);
    const dispatch = useDispatch()
    const open = useSelector(state => state.sideMenuOpened)
    const handleDrawerOpen = () => {
        // setOpen(true);
        dispatch(setMenuOpen(true))
    };
    const handleDrawerClose = () => {
        // setOpen(false);
        dispatch(setMenuOpen(false))
    };

    const customerInfo = useSelector(state => state.customerInfo);


    const CustomRouterLink = forwardRef((props, ref) => (
        <div ref={ref} style={{flexGrow: 1}}>
            <Link {...props} />
        </div>
    ));

    return (
        <div className={classes.root}>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <Typography variant="subtitle1" component="h5" className={classes.toolBarUser}>
                        {
                            customerInfo.root
                                ? <VerifiedUserIcon color={"secondary"} style={{marginRight: 8}}/>
                                : <AccountCircleIcon color={"secondary"} style={{marginRight: 8}}/>
                        }

                        <Typography variant="button" display="block">
                            {decodeURIComponent(escape(customerInfo.unm))}
                        </Typography>
                    </Typography>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon/>
                    </IconButton>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon/>
                    </IconButton>
                </div>
                <div>
                    <Typography variant="caption" display="block" gutterBottom className={classes.toolBarUser}>
                        {decodeURIComponent(escape(customerInfo.eml))}
                    </Typography>
                </div>
                <Divider/>

                <List>
                    <ListItem button to={'/dashboard'} component={CustomRouterLink}>
                        <ListItemIcon>
                            <DashboardIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('mainmenu.dashboard')}/>
                    </ListItem>
                </List>
                <NodesTree/>
            </Drawer>
        </div>
    );
}
