import React from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DocumentTitle from 'react-document-title'
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            height: '100vh',
            justifyContent: 'center',
        },
        paper: {
            width: "90%",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
        },
        image404: {
            display: 'block',
            margin: '0 auto',
            width: '100%',
            maxWidth:440
        },
    }),
);


export default function NoMatch() {
    const classes = useStyles();
    const {t} = useTranslation()

    return (
        <DocumentTitle title={'FISH-Andromeda || Denied'}>
            <div className={classes.root}>
                <Paper className={classes.paper} elevation={0}>
                    <img alt='Not Found' src="./images/pages/401.png" className={classes.image404}/>
                    <Typography variant="h2" style={{textAlign: "center"}}>Access Denied</Typography>
                    <Button variant="outlined" color="primary" href="/" style={{marginTop: "50px"}}>
                        {t('mainmenu.home')}
                    </Button>
                </Paper>
            </div>
        </DocumentTitle>
    );
}

