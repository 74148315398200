import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from './theme';
import {StylesProvider, createGenerateClassName} from '@material-ui/core/styles';
import {reducers} from "./redux";
import './plugins/i18n';
import App from "./App";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';
import DateFnsUtils from "@date-io/date-fns";
import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";

const crypto = require('crypto'),
    shasum = crypto.createHash('sha1');
shasum.update('ccard');

const generateClassName = createGenerateClassName({
    productionPrefix: '__' + shasum.digest('hex').substr(0, 7),
    disableGlobal: true
});


const middlewares = [thunk];
let devtools = x => x;

if (
    process.env.NODE_ENV !== "production" &&
    process.browser &&
    window.__REDUX_DEVTOOLS_EXTENSION__
) {
    devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
}


const store = createStore(reducers, compose(applyMiddleware(...middlewares), devtools))//applyMiddleware(thunk))

const render = () => {
    ReactDOM.render(
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Suspense fallback={(<div>Loading</div>)}>
                            <App/>
                            <NotificationContainer/>
                        </Suspense>
                    </MuiPickersUtilsProvider>
                </Provider>
            </ThemeProvider>
        </StylesProvider>,
        document.getElementById('root')
    )
};

render();
