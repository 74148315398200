import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from "react-i18next";
import CurrencyFormat from "react-currency-format";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
    root: {
        width: 320,
        margin: '0 auto'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    cardActions: {
        justifyContent: "space-around"
    }
});

const CardInfo = ({cardInfo}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <>
            <Card className={classes.root} elevation={5}>
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {t('card')}
                    </Typography>
                    <Typography variant="h5" component="h2">
                        {cardInfo.card_uid}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        {cardInfo.customer.name} {cardInfo.customer.mobile}
                    </Typography>
                    <Typography variant="body2" component="div">
                        {/*{t('credit')}: {cardInfo.account.credit}*/}
                        <CurrencyFormat
                            fullWidth
                            size="small"
                            prefix={t('credit') + ': '}
                            suffix=" грн"
                            decimalScale={2}
                            disabled
                            customInput={(props) => <TextField {...props} />}
                            value={cardInfo.account.credit/100}
                        />
                        <CurrencyFormat
                            fullWidth
                            size="small"
                            prefix={t('bonus') + ': '}
                            suffix=" грн"
                            decimalScale={2}
                            disabled
                            customInput={(props) => <TextField {...props} />}
                            value={cardInfo.account.bonus/100}
                        />
                        {/*<br/>*/}
                        {/*{t('bonus')}: {cardInfo.account.bonus}*/}
                    </Typography>
                </CardContent>
            </Card>
        </>
    );
}

export default CardInfo
