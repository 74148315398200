import React, {useEffect, useState} from 'react';
import {fade, makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import * as AuthService from '../../plugins/AuthService'

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1
    },
    cardHeader: {
        backgroundColor: fade(theme.palette.primary.main, 0.75),
        color: theme.palette.common.white

    }
}));

export default function Dashboard() {
    const classes = useStyles();
    const {t} = useTranslation();

    const [authedUser, setAuthedUser] = useState();
    useEffect(() => {
        authedUser || setAuthedUser(AuthService.isAuthenticated())
    }, [authedUser]);

    return (
        <Container maxWidth={false} className={classes.root} disableGutters={true}>
            <CssBaseline/>

            <Card className={classes.root}>
                <CardHeader
                    title={t('dashboard.title')}
                    /*subheader={t('dashboard.subheader')}*/
                    className={classes.cardHeader}
                />
                <CardContent>
                    <Typography variant="h2" style={{textAlign: "center"}}>
                        {t('common.selectNode')}
                    </Typography>
                </CardContent>
            </Card>

        </Container>
    );
}
