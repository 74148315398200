import React, {useEffect, useState} from 'react';
import DocumentTitle from 'react-document-title';
import {fade, makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Container, Grid, withStyles} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import {useDispatch, useSelector} from "react-redux";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import WidgetsIcon from "@material-ui/icons/Widgets";
import Badge from '@material-ui/core/Badge';
import AddNodeDialog from "./addNodeDialog";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import AddUserDialog from "./addUserDialog";
import AddCardDialog from "./addCardDialog";
import Divider from "@material-ui/core/Divider";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import BuildIcon from "@material-ui/icons/Build";
import FaceIcon from "@material-ui/icons/Face";
import GetRoomPinDialog from "./getRoomPinDialog";
import CardDepositDialog from "./cardDepositDialog";
import Typography from "@material-ui/core/Typography";
import CardWithdrawDialog from "./cardWithdrawDialog";
import CardBalanceDialog from "./cardBalanceDialog";
import axios from 'axios'
import Preloader from "../common/Preloader";
import PointCard from "./PointCard";
import GetRoomCardsListDialog from "./getRoomCardsListDialog";
import GetRoomCustomersListDialog from "./getRoomCustomersListDialog";
import GetRoomTransactionsDialog from "./getRoomTransactionsDialog";

const _ = require('lodash')

const useStyles = makeStyles((theme) => (
    {
        root: {
            flexGrow: 1,
        },
        cardHeader: {
            backgroundColor: fade(theme.palette.primary.main, 0.75),
            color: theme.palette.common.white

        },
        actionsNav: {
            justifyContent: "space-around",
            flexWrap: "wrap",
            display: "flex"
        },
        cardHeaderAction: {
            textAlign: "center",
            alignSelf: "center"
        }
    }
));

const nodeIcon = type => {
    switch (type) {
        case 'node':
            return <DeviceHubIcon fontSize="large"/>
        case 'room':
            return <MeetingRoomIcon fontSize="large"/>
        case 'group':
            return <WidgetsIcon fontSize="large"/>
        case 'point':
            return <MoveToInboxIcon fontSize="large"/>
        default:
            return null

    }
}

const userIcon = type => {
    switch (type) {
        case 'owner':
            return <AssignmentIndIcon/>
        case 'manager':
            return <HowToRegIcon/>
        case 'tech':
            return <BuildIcon/>
        case 'operator':
            return <FaceIcon/>
        default:
            return null

    }
}

const StyledBadge = withStyles((theme) => ({
    root: {
        marginTop: -16
    },
    badge: {
        right: -24,
        top: 0,
    },
}))(Badge);

export default function NodeIndex(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const dispatch = useDispatch()

    const nodeTreeItems = useSelector(state => state.sideMenuItems)
    const sideMenuExpanded = useSelector(state => state.sideMenuExpanded)

    const flattenItems = (items, key) => {
        return items.reduce((flattenedItems, item) => {
            flattenedItems.push(item)
            if (Array.isArray(item[key])) {
                flattenedItems = flattenedItems.concat(flattenItems(item[key], key))
                flattenedItems.map(i => {
                    i.expanded = props.match.params.id.startsWith(i.id) ? true : false
                    return i
                })
            }
            return flattenedItems
            // eslint-disable-next-line
        }, [])
    }

    const item = _.find(flattenItems(nodeTreeItems, 'children'), ['id', props.match.params.id])

    useEffect(() => {

        if (!sideMenuExpanded.length) {
            let expanded = {}
            flattenItems(nodeTreeItems, 'children').forEach(i => {
                expanded[i.id] = i.expanded
            })
            dispatch({
                type: 'SET_EXPANDED',
                payload: expanded
            })
        }
        // eslint-disable-next-line
    }, [item, sideMenuExpanded.length])

    const [points, setPoints] = useState([])

    useEffect(() => {
        if (item && item.type === 'room' && !points.length) {
            axios
                .get(`/api/v1/nodes/${item.id}/points`)
                .then(response => {
                    setPoints(response.data)
                })

        }
    }, [item, points.length])

    const handlePointsUpdate = update => {
        update && setPoints([])
    }

    return (
        <DocumentTitle title={'CCARD || ' + t('nodePage.docTitle')}>
            <Container maxWidth={false} className={classes.root} disableGutters={true}>
                <CssBaseline/>

                {
                    item
                        ? <Card className={classes.root}>
                            <CardHeader
                                title={
                                    <>
                                        {nodeIcon(item.type)}
                                        <StyledBadge badgeContent={t(`node_types.${item.type}`)} color="primary">
                                            {item.name}
                                        </StyledBadge>

                                    </>
                                }
                                subheader={item.id}
                                className={classes.cardHeader}
                                classes={{
                                    action: classes.cardHeaderAction
                                }}
                                action={
                                    <>
                                        {userIcon(item.permissions[0])}<br/>
                                        {t(`user_types.${item.permissions[0]}`)}
                                    </>
                                }
                            />
                            <CardContent className={classes.actionsNav}>
                                {!!item.permissions.length &&
                                <>
                                    <Grid container spacing={1} style={{margin: '24px 0 4px 0'}}>
                                        {['node', 'room'].includes(item.type) &&
                                        <Typography variant="h5" color="primary">{t('node')}</Typography>}
                                    </Grid>
                                    <Grid container spacing={1} style={{margin: 2}}>
                                        {['node', 'room'].includes(item.type) && <AddNodeDialog parentNode={item}/>}
                                        {['node', 'room'].includes(item.type) && <AddUserDialog parentNode={item}/>}
                                        {['room'].includes(item.type) && <GetRoomPinDialog parentNode={item}/>}
                                    </Grid>
                                    <Grid container spacing={1} style={{margin: '24px 0 4px 0'}}>
                                        {['room'].includes(item.type) &&
                                        <Typography variant="h5" color="primary">{t('card')}</Typography>}
                                    </Grid>
                                    <Grid container spacing={1} style={{margin: 2}}>
                                        {['room'].includes(item.type) && <AddCardDialog parentNode={item}/>}
                                        {['room'].includes(item.type) && <CardBalanceDialog parentNode={item}/>}
                                        {['room'].includes(item.type) && <CardDepositDialog parentNode={item}/>}
                                        {['room'].includes(item.type) && <CardWithdrawDialog parentNode={item}/>}
                                    </Grid>
                                    <Grid container spacing={1} style={{margin: 2}}>
                                        {['room'].includes(item.type) && <GetRoomCardsListDialog parentNode={item}/>}
                                        {['room'].includes(item.type) && ['owner', 'manager'].includes(item.permissions[0]) &&
                                        <GetRoomCustomersListDialog parentNode={item}/>}
                                        {['room'].includes(item.type) && <GetRoomTransactionsDialog parentNode={item}/>}
                                    </Grid>
                                    <Grid container spacing={1} style={{margin: '24px 0 4px 0'}}>
                                        {['room'].includes(item.type) &&
                                        <Typography variant="h5" color="primary">{t('points')}</Typography>}
                                    </Grid>
                                    <Grid container spacing={1} style={{margin: 2}}>
                                        {
                                            ['room'].includes(item.type) && !points.length && !item.permissions.includes('operator')
                                                ? <Preloader type="linear"/>
                                                : points.map(point =>
                                                    <PointCard
                                                        {...{...point, nodeid: item.id}}
                                                        key={point.id}
                                                        onPointsUpdate={args => handlePointsUpdate(args)}
                                                    />
                                                )
                                        }
                                    </Grid>
                                </>
                                }
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <div dangerouslySetInnerHTML={{__html: t('nodePage.intro')}}/>
                            </CardContent>
                            <CardActions>

                            </CardActions>
                        </Card>
                        : <Preloader/>
                }

            </Container>
        </DocumentTitle>
    );
};
