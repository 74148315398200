import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import DashboardLayout from "./index";
import * as AuthService from "../../plugins/AuthService";


const DashboardLayoutRoutes = ({component: Component, ...rest}) => {

    return (
        <Route {...rest} render={props =>

            AuthService.isAuthenticated() && !AuthService.isAuthenticated().adm
                ?
                (
                    <DashboardLayout>
                        <Component {...props}/>
                    </DashboardLayout>
                )
                :
                AuthService.isAuthenticated()
                    ? (
                        <Redirect
                            to={{
                                pathname: "/denied",
                                state: {from: props.location}
                            }}
                        />
                    )
                    : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {from: props.location}
                            }}
                        />
                    )


        }/>
    )
}

export default DashboardLayoutRoutes
