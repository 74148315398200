import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from "react-i18next";
import axios from 'axios'
import 'react-notifications/lib/notifications.css';
import {NotificationManager} from 'react-notifications';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import {fade, Grid} from "@material-ui/core";
import CurrencyFormat from "react-currency-format";
import CardInfo from "./cardINFO";
import SpeakerPhoneIcon from '@material-ui/icons/SpeakerPhone';
import Fab from "@material-ui/core/Fab";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // width: 800,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        width: 64,
        height: 64
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flex: 1,
        '& span': {
            // color: theme.palette.common.white
        }
    },
    image: {
        width: '100%'
    },
    formControl: {
        flex: 1,
        display: 'flex'
    },
    radioGroup: {
        display: 'flex',
        justifyContent: "space-around",
        marginTop: 16,
        marginBottom: 8
    },
    formControlLabel: {
        borderRadius: 9,
        border: '1px solid #DDD',
        padding: 8,
        width: 110,
        margin: 4
    },
    mainBtn: {
        minHeight: 144,
        flexWrap: 'wrap',
        display: "block"
    },
    mainBtnIcon: {
        display: 'block',
        width: '100%',
        fontSize: '6rem',
        color: fade(theme.palette.primary.main, 0.8),
        '&:hover': {
            color: fade(theme.palette.primary.main, 1)
        }
    },
    backdrop: {
        zIndex: 1999,
        position: "absolute",
        backgroundColor: 'rgba(255,255,255,0.8)',
        flexWrap: "wrap",
        alignItems: "baseline",
        '& h2': {
            textAlign: 'center',
            width: '100%',
            backgroundColor: '#FFF',
            color: theme.palette.primary.main,
            lineHeight: '56px',
            fontWeight: theme.typography.fontWeightLight
        }
    }
}));

export default function CardWithdrawDialog(props) {

    // console.log('CardWithdrawDialog(props): ', props)

    const {t} = useTranslation();
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [readerMsg, setReaderMsg] = useState(t('waiting_for_card'));
    const [readerError, setReaderError] = useState(false);
    const handleCloseBackdrop = () => setOpenBackdrop(false)

    const handleClickOpen = () => {
        setOpen(true);
        setCardInfo(props.cardUid)
    };

    const handleClose = () => {
        setOpen(false);
        setNewData({
            name: '',
            node: props.parentNode.id,
            node_type: '',
            amount: '',
            cardUid: ''
        })
    };

    const [cardInfo, setCardInfo] = React.useState(props.cardUid);

    const [newData, setNewData] = useState({
        name: '',
        node: props.parentNode.id,
        node_type: '',
        amount: '',
        cardUid: ''
    });

    const onChange = ({target: {name, value}}) => {
        setNewData({...newData, [name]: value})
    };

    const onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        console.log('submit')

        axios
            .post(`/api/v1/nodes/${props.parentNode.id}/withdraw/${newData.cardUid}`,
                {
                    card_id: newData.cardUid,
                    cardid: newData.cardUid,
                    amount: parseFloat(newData.amount) * 100
                }
            )
            .then(response => {
                console.log('/api/v1/nodes  response: ', response)
                if (response.data.card_id) {
                    setCardInfo({...response.data, card_uid: newData.cardUid})
                    NotificationManager.success(t('card_withdrawed'), 'Success!', 5000);
                    handleClose()
                }
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
            })
    };

    const onSubmitFindCard = (event) => {
        if (event) {
            event.preventDefault();
        }

        console.log('submit')

        axios.get(`/api/v1/nodes/${props.parentNode.id}/card/${newData.cardUid}`)
            .then(response => {
                console.log('/api/v1/nodes  response: ', response)
                if (response.data.card_id) {
                    setCardInfo({...response.data, card_uid: newData.cardUid})
                }

            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
            })
    };

    useEffect(() => {
        setNewData({...newData, node: props.parentNode.id})
        // eslint-disable-next-line
    }, [props.parentNode.id]);

    const readCard = () => {

        setOpenBackdrop(true)
        setReaderMsg(t('waiting_for_card'))
        setReaderError(false)

        try {
            axios
                .get('http://127.0.0.1:8080/cardread')
                .then(res => {
                    if (res.data.status && res.data.card) {
                        setNewData({...newData, cardUid: res.data.card})
                        setOpenBackdrop(false)
                    }

                })
                .catch(error => {
                    console.log('axios catch: ', error)
                    setReaderMsg(error.message)
                    setReaderError(true)
                    setTimeout(() => setOpenBackdrop(false), 3456)
                    /*const test = {
                        data: {
                            "card": "b2d9beea",
                            "status": true,
                            "ts": 1606835980
                        }
                    }
                    if (test.data.status && test.data.card) {
                        setNewData({...newData, cardUid: test.data.card})
                    }*/
                })
                .finally(() => {
                    // setOpenBackdrop(false)
                })
        } catch (e) {
            console.log('Catch: ', e)
        }

    }


    return (
        <div style={{flexGrow: 1, padding: '0 4px'}}>
            <Button
                variant="outlined"
                color="primary"
                aria-label="add"
                onClick={handleClickOpen}
                size="large"
                style={{width: '100%'}}
                className={classes.mainBtn}
            >
                <Typography variant="h6" component="h6">{t('to_withdraw_card')}</Typography>
                <Icon fontSize="large" classes={{root: classes.mainBtnIcon}}>money_off</Icon>
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                    classes={{paper: classes.paper}}>
                <div className={classes.paper} style={{minWidth: '90%'}}>

                    <DialogTitle id="form-dialog-title">{t('to_withdraw_card')} "{props.parentNode.name}"</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="caption" component="div">
                            <div dangerouslySetInnerHTML={{__html: t('withdraw_card_dialog_description')}}/>
                        </DialogContentText>
                    </DialogContent>
                    {
                        props.cardUid ||
                        <form className={classes.form} noValidate onSubmit={onSubmitFindCard}>
                            <DialogContent>
                                <Grid container>
                                    <Grid item xs>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            name="cardUid"
                                            label={t('card_uid')}
                                            fullWidth
                                            value={newData.cardUid}
                                            onInput={onChange}
                                            error={!newData.cardUid}
                                            helperText={!newData.cardUid ? t('field_is_required') : ' '}
                                            disabled={!!cardInfo}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Fab color={"primary"} onClick={readCard}>
                                            <SpeakerPhoneIcon/>
                                        </Fab>
                                    </Grid>
                                </Grid>
                                <Backdrop className={classes.backdrop} open={openBackdrop}
                                          onClick={handleCloseBackdrop}>
                                    <h2 style={{color: readerError ? '#F00' : 'inherit'}}>{readerMsg}</h2>
                                    {readerError || <CircularProgress color="primary" size={72} thickness={3}/>}
                                </Backdrop>
                            </DialogContent>
                            {
                                !!cardInfo && cardInfo.card_id
                                    ? <CardInfo cardInfo={cardInfo}/>
                                    : <DialogActions>
                                        <Button onClick={handleClose} color="primary" variant="outlined"
                                                className={classes.submit}>
                                            {t('cancel')}
                                        </Button>
                                        <Button type="submit" variant="contained" color="primary"
                                                className={classes.submit}>
                                            {t('to_find_card')}
                                        </Button>
                                    </DialogActions>
                            }
                        </form>
                    }


                    {
                        !!cardInfo &&
                        <form className={classes.form} noValidate onSubmit={onSubmit}>
                            <DialogContent>
                                <CurrencyFormat
                                    autoFocus
                                    margin="dense"
                                    name="amount"
                                    label={t('amount')}
                                    fullWidth
                                    error={!newData.amount}
                                    helperText={!newData.amount ? t('field_is_required') : ' '}

                                    value={newData.amount}
                                    customInput={(props) => <TextField {...props} />}
                                    suffix=" грн."
                                    decimalScale={2}
                                    onValueChange={(values) => {
                                        const {value} = values;
                                        setNewData({...newData, amount: value})
                                    }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary" variant="outlined"
                                        className={classes.submit}>
                                    {t('cancel')}
                                </Button>
                                <Button type="submit" variant="contained" color="primary"
                                        className={classes.submit}>
                                    {t('to_withdraw_card')}
                                </Button>
                            </DialogActions>
                        </form>
                    }

                    {/*<pre>{JSON.stringify(cardInfo, null, 4)}</pre>*/}
                    {/*<pre>{JSON.stringify(newData, null, 4)}</pre>*/}
                </div>
            </Dialog>
        </div>
    );
}
