import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';


const EmptyLayout = (props) => {
    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="xl">
                <Typography component="div" style={{ height: '100vh' }} >
                    {props.children}
                </Typography>
            </Container>
        </React.Fragment>

    )
}

export default EmptyLayout
