import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from "react-i18next";
import axios from 'axios'
import 'react-notifications/lib/notifications.css';
import {NotificationManager} from 'react-notifications';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import {fade, Grid} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {useTheme} from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import CurrencyFormat from 'react-currency-format';
import CardInfo from "./cardINFO";
import CardActions from "@material-ui/core/CardActions";
import SpeakerPhoneIcon from '@material-ui/icons/SpeakerPhone';
import Fab from "@material-ui/core/Fab";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // width: 500,
    },
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        width: 64,
        height: 64
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flex: 1,
        '& span': {
            // color: theme.palette.common.white
        }
    },
    image: {
        width: '100%'
    },
    formControl: {
        flex: 1,
        display: 'flex'
    },
    radioGroup: {
        display: 'flex',
        justifyContent: "space-around",
        marginTop: 16,
        marginBottom: 8
    },
    formControlLabel: {
        borderRadius: 9,
        border: '1px solid #DDD',
        padding: 8,
        minWidth: 110,
        margin: 4
    },
    mainBtn: {
        minHeight: 144,
        flexWrap: 'wrap',
        display: "block"
    },
    mainBtnIcon: {
        display: 'block',
        width: '100%',
        fontSize: '6rem',
        color: fade(theme.palette.primary.main, 0.8),
        '&:hover': {
            color: fade(theme.palette.primary.main, 1)
        }
    },
    backdrop: {
        zIndex: 1999,
        position: "absolute",
        backgroundColor: 'rgba(255,255,255,0.8)',
        flexWrap: "wrap",
        alignItems: "baseline",
        '& h2': {
            textAlign: 'center',
            width: '100%',
            backgroundColor: '#FFF',
            color: theme.palette.primary.main,
            lineHeight: '56px',
            fontWeight: theme.typography.fontWeightLight
        }
    }
}));

//t('account')

export default function AddCardDialog(props) {

    const {t} = useTranslation()
    const classes = useStyles();
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [readerMsg, setReaderMsg] = useState(t('waiting_for_card'));
    const [readerError, setReaderError] = useState(false);
    const handleCloseBackdrop = () => setOpenBackdrop(false)

    const [newData, setNewData] = useState({
        mobile: '+380',
        card_uid: '',
        pin: ''
    });
    const onChange = ({target: {name, value}}) => setNewData({...newData, [name]: value});

    useEffect(() => {
        if (newData.mobile.length < 4) {
            setNewData({...newData, mobile: '+380'});
        }
    }, [newData, newData.mobile.length])

    const [pinSent, setPinSent] = React.useState(false);
    const [cardInfo, setCardInfo] = React.useState(null)
    /*
    {
        "account": {
            "credit": 123456,
            "bonus": 0
        },
        "card_id": 6,
        "card_uid": "12345678",
        "customer": {
            "name": "",
            "mobile": "38097*****18"
        }
    }
    */

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => setValue(newValue);

    const onSubmitGetPin = (event) => {
        if (event) {
            event.preventDefault();
        }
        console.log('onSubmitGetPin')

        if (!newData.mobile) {
            NotificationManager.error(`${t('mobile')}: ${t('field_is_required')}`, 'Oops!', 6666)
            return
        }

        axios.post(`/api/v1/nodes/${props.parentNode.id}/sendpin`, {mobile: newData.mobile})
            .then(response => {
                console.log(`/api/v1/nodes/${props.parentNode.id}/sendpin`, response)
                if (response.status === 200) {
                    NotificationManager.success(t('pin_sent_to') + newData.mobile, 'Success!', 3000);
                    setPinSent(true)
                    setValue(1)

                }
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
            })
    };

    const onSubmitAddCard = (event) => {
        if (event) {
            event.preventDefault();
        }
        console.log('onSubmitGetPin')
        let errors = 0;

        Object.keys(newData).forEach(e => {
            if (!newData[e]) {
                NotificationManager.error(`${t(e)}: ${t('field_is_required')}`, 'Oops!', 6666)
                errors++
            }
        });

        if (errors) return

        axios.post(`/api/v1/nodes/${props.parentNode.id}/addcard`, newData)
            .then(response => {
                console.log(`/api/v1/nodes/${props.parentNode.id}/addcard`, response)
                if (response.data.card_id) {
                    NotificationManager.success(t('card_added'), 'Success!', 5000);
                    setPinSent(true)
                    setCardInfo({...response.data, card_uid: newData.card_uid})
                    setTimeout(() => setValue(2), 666)

                }
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 5000);
            })
    };

    useEffect(() => {
        setNewData({...newData, node: props.parentNode.id})
        // eslint-disable-next-line
    }, [props.parentNode.id])

    const readCard = () => {

        setOpenBackdrop(true)
        setReaderMsg(t('waiting_for_card'))
        setReaderError(false)

        try {
            axios
                .get('http://127.0.0.1:8080/cardread')
                .then(res => {
                    if (res.data.status && res.data.card) {
                        setNewData({...newData, card_uid: res.data.card})
                        setOpenBackdrop(false)
                    }

                })
                .catch(error => {
                    console.log('axios catch: ', error)
                    setReaderMsg(error.message)
                    setReaderError(true)
                    setTimeout(() => setOpenBackdrop(false), 3456)
                    /*const test = {
                        data: {
                            "card": "b2d9beea",
                            "status": true,
                            "ts": 1606835980
                        }
                    }
                    if (test.data.status && test.data.card) {
                        setNewData({...newData, card_uid: test.data.card})
                    }*/
                })
                .finally(() => {
                    // setOpenBackdrop(false)
                })
        } catch (e) {
            console.log('Catch: ', e)
        }

    }

    return (
        <div style={{flexGrow: 1, padding: '0 4px'}}>
            <Button
                variant="outlined"
                color="primary"
                aria-label="add"
                onClick={handleClickOpen}
                size="large"
                style={{width: '100%'}}
                className={classes.mainBtn}
            >
                <Typography variant="h6" component="h6">{t('add_card')}</Typography>
                <Icon fontSize="large" classes={{root: classes.mainBtnIcon}}>credit_card</Icon>
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <div className={classes.paper}>

                    <DialogTitle id="form-dialog-title">{t('add_card_to')} {props.parentNode.name}</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="caption" component="div">
                            <div dangerouslySetInnerHTML={{__html: t('add_card_dialog_description')}}/>
                        </DialogContentText>
                    </DialogContent>

                    <DialogContent style={{width: '100%'}}>

                        <div className={classes.root}>
                            <AppBar position="static" color="transparent" elevation={0}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                >
                                    <Tab label={t('get_pin')} {...a11yProps(0)} />
                                    {
                                        pinSent &&
                                        <Tab label={t('add_card')} {...a11yProps(1)} />
                                    }
                                    {
                                        cardInfo &&
                                        <Tab label={t('card')} {...a11yProps(2)} />
                                    }
                                </Tabs>
                            </AppBar>

                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <form className={classes.form} noValidate onSubmit={onSubmitGetPin}>
                                    <CurrencyFormat
                                        autoFocus
                                        margin="dense"
                                        name="mobile"
                                        label={t('mobile')}
                                        type="tel"
                                        fullWidth
                                        error={!newData.mobile}
                                        helperText={!newData.mobile ? t('field_is_required') : ' '}

                                        value={newData.mobile}
                                        customInput={(props) => <TextField {...props} />}
                                        format="+## (###) ###-####"
                                        mask="_"
                                        onValueChange={(values) => {
                                            const {value} = values;
                                            setNewData({...newData, mobile: value})
                                        }}
                                    />
                                    <DialogActions>
                                        <Button type="reset" onClick={handleClose} color="primary" variant="outlined"
                                                className={classes.submit}>
                                            {t('cancel')}
                                        </Button>
                                        <Button type="submit" variant="contained" color="primary"
                                                className={classes.submit}>
                                            {t('send_mobile')}
                                        </Button>
                                    </DialogActions>
                                </form>
                            </TabPanel>
                            {
                                pinSent &&
                                <TabPanel value={value} index={1} dir={theme.direction}>
                                    <form className={classes.form} noValidate onSubmit={onSubmitAddCard}>
                                        <CurrencyFormat
                                            margin="dense"
                                            name="mobile"
                                            label={t('mobile')}
                                            type="tel"
                                            fullWidth
                                            error={!newData.mobile}
                                            helperText={!newData.mobile ? t('field_is_required') : ' '}

                                            value={newData.mobile}
                                            customInput={(props) => <TextField {...props} />}
                                            format="+## (###) ###-####"
                                            mask="_"
                                            onValueChange={(values) => {
                                                const {value} = values;
                                                setNewData({...newData, mobile: value})
                                            }}
                                            disabled={!!newData.mobile}
                                        />
                                        <Grid container>
                                            <Grid item xs>
                                                <TextField
                                                    autoFocus
                                                    margin="dense"
                                                    name="card_uid"
                                                    label={t('card_uid')}
                                                    fullWidth
                                                    value={newData.card_uid}
                                                    onInput={onChange}
                                                    error={!newData.card_uid}
                                                    helperText={!newData.card_uid ? t('field_is_required') : ' '}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Fab color={"primary"} onClick={readCard}>
                                                    <SpeakerPhoneIcon/>
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                        <Backdrop className={classes.backdrop} open={openBackdrop}
                                                  onClick={handleCloseBackdrop}>
                                            <h2 style={{color: readerError ? '#F00' : 'inherit'}}>{readerMsg}</h2>
                                            {readerError || <CircularProgress color="primary" size={72} thickness={3}/>}
                                        </Backdrop>
                                        <TextField
                                            margin="dense"
                                            name="pin"
                                            label={t('pin')}
                                            fullWidth
                                            value={newData.pin}
                                            onInput={onChange}
                                            error={!newData.pin}
                                            helperText={!newData.pin ? t('field_is_required') : ' '}
                                        />
                                        <DialogActions>
                                            <Button type="reset" onClick={handleClose} color="primary"
                                                    variant="outlined"
                                                    className={classes.submit}>
                                                {t('cancel')}
                                            </Button>
                                            <Button type="submit" variant="contained" color="primary"
                                                    className={classes.submit}>
                                                {t('add_card')}
                                            </Button>
                                        </DialogActions>
                                    </form>
                                </TabPanel>
                            }
                            {
                                cardInfo &&
                                <TabPanel value={value} index={2} dir={theme.direction}>
                                    <CardInfo cardInfo={cardInfo}/>
                                    <CardActions className={classes.cardActions}
                                                 style={{justifyContent: "space-around"}}>
                                        <Button size="small" color="primary"
                                                variant="contained">{t('to_deposit_card')}</Button>
                                        <Button size="small" color="secondary"
                                                variant="contained">{t('to_withdraw_card')}</Button>
                                    </CardActions>
                                </TabPanel>
                            }
                        </div>

                    </DialogContent>
                    {/*<pre>{JSON.stringify(newData, null, 4)}</pre>*/}
                </div>
            </Dialog>
        </div>
    );
}
