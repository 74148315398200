import React, { useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from "react-i18next";
import axios from 'axios'
import 'react-notifications/lib/notifications.css';
import {NotificationManager} from 'react-notifications';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import {fade} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { DataGrid } from '@material-ui/data-grid';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // width: 500,
    },
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        width: 64,
        height: 64
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flex: 1,
        '& span': {
            // color: theme.palette.common.white
        }
    },
    image: {
        width: '100%'
    },
    formControl: {
        flex: 1,
        display: 'flex'
    },
    radioGroup: {
        display: 'flex',
        justifyContent: "space-around",
        marginTop: 16,
        marginBottom: 8
    },
    formControlLabel: {
        borderRadius: 9,
        border: '1px solid #DDD',
        padding: 8,
        minWidth: 110,
        margin: 4
    },
    mainBtn: {
        minHeight: 144,
        flexWrap: 'wrap',
        display: "block"
    },
    mainBtnIcon: {
        display: 'block',
        width: '100%',
        fontSize: '6rem',
        color: fade(theme.palette.primary.main, 0.8),
        '&:hover': {
            color: fade(theme.palette.primary.main, 1)
        }
    }
}));

export default function GetRoomCustomersListDialog(props) {

    const {t} = useTranslation()
    const classes = useStyles();

    const [customersList, setCustomersList] = useState([]);

    const columns = [
        { field: 'id', headerName: t('id'), width:60 },
        { field: 'name', headerName: t('customer.name'), flex:1},
        { field: 'email', headerName: t('customer.email'), flex:1},
        { field: 'node_id', headerName: t('node'), width:100},
        { field: 'permission', headerName: t('customer.permission'), flex:1},
    ];

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
        axios
            .get(`/api/v1/nodes/${props.parentNode.id}/acls`)
            .then(response => {
                console.log(response)
                setCustomersList(response.data.users)
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
            })
    };
    const handleClose = () => setOpen(false);

    return (
        <div style={{flexGrow: 1, padding: '0 4px'}}>
            <Button
                variant="outlined"
                color="primary"
                aria-label="add"
                onClick={handleClickOpen}
                size="large"
                style={{width: '100%'}}
                className={classes.mainBtn}
            >
                <Typography variant="h6" component="h6">{t('customer.list')}</Typography>
                <Icon fontSize="large" classes={{root: classes.mainBtnIcon}}>contacts</Icon>
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={"md"}>
                <div className={classes.paper}>

                    <DialogTitle id="form-dialog-title" style={{width:'100%'}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <span/>
                            <span>{t('customer.list')} "{props.parentNode.name}"</span>
                            <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </DialogTitle>

                    <DialogContent style={{width: '100%'}}>
                        <div style={{ height: 400, width: '100%' }}>
                            {customersList.length && <DataGrid rows={customersList} columns={columns} pageSize={5} />}
                        </div>
                    </DialogContent>
                    {/*<pre>{JSON.stringify(customersList, null, 4)}</pre>*/}
                </div>
            </Dialog>
        </div>
    );
}
