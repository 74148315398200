import React, {useCallback, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import {useTranslation} from 'react-i18next';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({}));

const MainLayout = (props) => {
    const classes = useStyles();
    const {i18n} = useTranslation();


    const changeLanguage = useCallback((lng) => {
        localStorage.setItem('lang', lng);
        i18n.changeLanguage(lng);
    }, [i18n])

    useEffect(() => {
        if (localStorage.getItem('lang') && localStorage.getItem('lang') !== i18n.language) {
            changeLanguage(localStorage.getItem('lang'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <div style={{display: 'flex', background: '#FFF'}}>
            <main style={{flexGrow: 1, height: '100vh', overflow: 'auto', overflowX: 'hidden'}}>
                <Box>
                    {props.children}
                </Box>
                <Box pt={4} className={classes.footer}>
                    <Paper className={classes.paper} elevation={0}>
                        <Typography style={{textAlign: 'right', marginRight:16}}>
                            <small>CCARD &copy; {new Date().getFullYear()}</small>
                        </Typography>
                        </Paper>

                </Box>
            </main>
        </div>
    )
};

export default MainLayout;
