import React, {Fragment} from 'react';
import DocumentTitle from 'react-document-title';
import {useTranslation} from "react-i18next";
import Dashboard from "./Dashboard";

export default function DashboardIndex() {
    const {t} = useTranslation();

    return (
        <DocumentTitle title={'CCARD || ' + t('mainmenu.dashboard')}>
            <Fragment>
                <Dashboard/>
            </Fragment>
        </DocumentTitle>
    );
};
